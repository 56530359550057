import revive_payload_client_4sVQNw7RlN from "/codebuild/output/src2320306735/src/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/codebuild/output/src2320306735/src/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/codebuild/output/src2320306735/src/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/codebuild/output/src2320306735/src/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/codebuild/output/src2320306735/src/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/codebuild/output/src2320306735/src/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/codebuild/output/src2320306735/src/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_UciE0i6zes from "/codebuild/output/src2320306735/src/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import aos_Rx1DQ3If9A from "/codebuild/output/src2320306735/src/plugins/aos.js";
import api_QQzOodE9HA from "/codebuild/output/src2320306735/src/plugins/api.js";
import axios_sVCuMR6hEC from "/codebuild/output/src2320306735/src/plugins/axios.js";
import event_bus_2SmfyPo4o0 from "/codebuild/output/src2320306735/src/plugins/event_bus.js";
import fetch_Epa1FcTLYd from "/codebuild/output/src2320306735/src/plugins/fetch.js";
import fontawesome_klhsrycjcK from "/codebuild/output/src2320306735/src/plugins/fontawesome.js";
import legacy_p3vBIu9RQX from "/codebuild/output/src2320306735/src/plugins/legacy.js";
import report_N7I8pUej6S from "/codebuild/output/src2320306735/src/plugins/report.js";
import vuex_owYp5qnaH8 from "/codebuild/output/src2320306735/src/plugins/vuex.js";
import z_audit_trail_PclN2V3Mrr from "/codebuild/output/src2320306735/src/plugins/z.audit_trail.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  chunk_reload_client_UciE0i6zes,
  aos_Rx1DQ3If9A,
  api_QQzOodE9HA,
  axios_sVCuMR6hEC,
  event_bus_2SmfyPo4o0,
  fetch_Epa1FcTLYd,
  fontawesome_klhsrycjcK,
  legacy_p3vBIu9RQX,
  report_N7I8pUej6S,
  vuex_owYp5qnaH8,
  z_audit_trail_PclN2V3Mrr
]