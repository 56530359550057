import { default as editJawOqo8tvBMeta } from "/codebuild/output/src2320306735/src/pages/admin/account_plans/[account_plan_id]/edit.vue?macro=true";
import { default as indexQ0l1QzzFVeMeta } from "/codebuild/output/src2320306735/src/pages/admin/account_plans/[account_plan_id]/index.vue?macro=true";
import { default as _91account_plan_id_938YUw7zwtrKMeta } from "/codebuild/output/src2320306735/src/pages/admin/account_plans/[account_plan_id].vue?macro=true";
import { default as editc4BFJDDiLaMeta } from "/codebuild/output/src2320306735/src/pages/admin/account_requested_plans/[account_requested_plan_id]/edit.vue?macro=true";
import { default as indexZDkIAJTZcxMeta } from "/codebuild/output/src2320306735/src/pages/admin/account_requested_plans/[account_requested_plan_id]/index.vue?macro=true";
import { default as _91account_requested_plan_id_93rKuB1RmwWvMeta } from "/codebuild/output/src2320306735/src/pages/admin/account_requested_plans/[account_requested_plan_id].vue?macro=true";
import { default as indexS8BfggGhg8Meta } from "/codebuild/output/src2320306735/src/pages/admin/account_requested_plans/index.vue?macro=true";
import { default as newEuzEGp97UyMeta } from "/codebuild/output/src2320306735/src/pages/admin/account_requested_plans/new.vue?macro=true";
import { default as account_requested_plansRdlIAdM90hMeta } from "/codebuild/output/src2320306735/src/pages/admin/account_requested_plans.vue?macro=true";
import { default as editQimktRD7oRMeta } from "/codebuild/output/src2320306735/src/pages/admin/accounts/[account_id]/account_requested_plans/[account_requested_plan_id]/edit.vue?macro=true";
import { default as indexMoLdsYlCRaMeta } from "/codebuild/output/src2320306735/src/pages/admin/accounts/[account_id]/account_requested_plans/[account_requested_plan_id]/index.vue?macro=true";
import { default as _91account_requested_plan_id_93c1Z9OxWadoMeta } from "/codebuild/output/src2320306735/src/pages/admin/accounts/[account_id]/account_requested_plans/[account_requested_plan_id].vue?macro=true";
import { default as index0D409IcruAMeta } from "/codebuild/output/src2320306735/src/pages/admin/accounts/[account_id]/account_requested_plans/index.vue?macro=true";
import { default as newUit1j3PZdTMeta } from "/codebuild/output/src2320306735/src/pages/admin/accounts/[account_id]/account_requested_plans/new.vue?macro=true";
import { default as account_requested_plansD85u4OAr9WMeta } from "/codebuild/output/src2320306735/src/pages/admin/accounts/[account_id]/account_requested_plans.vue?macro=true";
import { default as editQ8bWv7pfDgMeta } from "/codebuild/output/src2320306735/src/pages/admin/accounts/[account_id]/caller_ids/[caller_id_id]/edit.vue?macro=true";
import { default as indexcyb6c7oXBdMeta } from "/codebuild/output/src2320306735/src/pages/admin/accounts/[account_id]/caller_ids/[caller_id_id]/index.vue?macro=true";
import { default as _91caller_id_id_93JJHglvhHgrMeta } from "/codebuild/output/src2320306735/src/pages/admin/accounts/[account_id]/caller_ids/[caller_id_id].vue?macro=true";
import { default as index1rZDs2tQMUMeta } from "/codebuild/output/src2320306735/src/pages/admin/accounts/[account_id]/caller_ids/index.vue?macro=true";
import { default as newNeIahGmjsEMeta } from "/codebuild/output/src2320306735/src/pages/admin/accounts/[account_id]/caller_ids/new.vue?macro=true";
import { default as caller_ids68gMxMsVAcMeta } from "/codebuild/output/src2320306735/src/pages/admin/accounts/[account_id]/caller_ids.vue?macro=true";
import { default as editu9khS16bTrMeta } from "/codebuild/output/src2320306735/src/pages/admin/accounts/[account_id]/edit.vue?macro=true";
import { default as indexsyOhSbI0fMMeta } from "/codebuild/output/src2320306735/src/pages/admin/accounts/[account_id]/index.vue?macro=true";
import { default as editYf6HOH2wSIMeta } from "/codebuild/output/src2320306735/src/pages/admin/accounts/[account_id]/sms_maskings/[sms_masking_id]/edit.vue?macro=true";
import { default as indexIDrvCvHwlpMeta } from "/codebuild/output/src2320306735/src/pages/admin/accounts/[account_id]/sms_maskings/[sms_masking_id]/index.vue?macro=true";
import { default as _91sms_masking_id_93cVrQqla7vuMeta } from "/codebuild/output/src2320306735/src/pages/admin/accounts/[account_id]/sms_maskings/[sms_masking_id].vue?macro=true";
import { default as indexhqNYpl0BFRMeta } from "/codebuild/output/src2320306735/src/pages/admin/accounts/[account_id]/sms_maskings/index.vue?macro=true";
import { default as newnS3H5zLGcGMeta } from "/codebuild/output/src2320306735/src/pages/admin/accounts/[account_id]/sms_maskings/new.vue?macro=true";
import { default as sms_maskingsp3dslZAsMqMeta } from "/codebuild/output/src2320306735/src/pages/admin/accounts/[account_id]/sms_maskings.vue?macro=true";
import { default as _91account_id_93bPRhITGWLdMeta } from "/codebuild/output/src2320306735/src/pages/admin/accounts/[account_id].vue?macro=true";
import { default as indexT2JqUW5unjMeta } from "/codebuild/output/src2320306735/src/pages/admin/accounts/index.vue?macro=true";
import { default as wizard_plan_selectionL10hDqfqoEMeta } from "/codebuild/output/src2320306735/src/pages/admin/accounts/wizard_plan_selection.vue?macro=true";
import { default as wizardt1nMz2eNJiMeta } from "/codebuild/output/src2320306735/src/pages/admin/accounts/wizard.vue?macro=true";
import { default as accounts046bmTCOadMeta } from "/codebuild/output/src2320306735/src/pages/admin/accounts.vue?macro=true";
import { default as editYEl4ymv0tNMeta } from "/codebuild/output/src2320306735/src/pages/admin/admin_users/[admin_user_id]/edit.vue?macro=true";
import { default as indexrvVTpTCUmKMeta } from "/codebuild/output/src2320306735/src/pages/admin/admin_users/[admin_user_id]/index.vue?macro=true";
import { default as _91admin_user_id_93EMGUbhmxcEMeta } from "/codebuild/output/src2320306735/src/pages/admin/admin_users/[admin_user_id].vue?macro=true";
import { default as index2w5EwIjTUTMeta } from "/codebuild/output/src2320306735/src/pages/admin/admin_users/index.vue?macro=true";
import { default as newwasJTAxzCwMeta } from "/codebuild/output/src2320306735/src/pages/admin/admin_users/new.vue?macro=true";
import { default as admin_userscnUorn9n82Meta } from "/codebuild/output/src2320306735/src/pages/admin/admin_users.vue?macro=true";
import { default as indexNlP6vsJH69Meta } from "/codebuild/output/src2320306735/src/pages/admin/audit_trails/index.vue?macro=true";
import { default as audit_trailsDJTiir9V9fMeta } from "/codebuild/output/src2320306735/src/pages/admin/audit_trails.vue?macro=true";
import { default as editTeWG4xvRfyMeta } from "/codebuild/output/src2320306735/src/pages/admin/billings/[billing_id]/edit.vue?macro=true";
import { default as index6YxKWiRrXgMeta } from "/codebuild/output/src2320306735/src/pages/admin/billings/[billing_id]/index.vue?macro=true";
import { default as _91billing_id_93sRhyYobYmpMeta } from "/codebuild/output/src2320306735/src/pages/admin/billings/[billing_id].vue?macro=true";
import { default as indexe4V2qfwhdzMeta } from "/codebuild/output/src2320306735/src/pages/admin/billings/index.vue?macro=true";
import { default as newYaxl1QwYnHMeta } from "/codebuild/output/src2320306735/src/pages/admin/billings/new.vue?macro=true";
import { default as billingsVwrxOL8Df1Meta } from "/codebuild/output/src2320306735/src/pages/admin/billings.vue?macro=true";
import { default as editPidNaLJZ89Meta } from "/codebuild/output/src2320306735/src/pages/admin/caller_ids/[caller_id_id]/edit.vue?macro=true";
import { default as indexqck7sY0XNfMeta } from "/codebuild/output/src2320306735/src/pages/admin/caller_ids/[caller_id_id]/index.vue?macro=true";
import { default as _91caller_id_id_93WJhbBclO0AMeta } from "/codebuild/output/src2320306735/src/pages/admin/caller_ids/[caller_id_id].vue?macro=true";
import { default as index2pgyAOSt39Meta } from "/codebuild/output/src2320306735/src/pages/admin/caller_ids/index.vue?macro=true";
import { default as newUWkiQhODdqMeta } from "/codebuild/output/src2320306735/src/pages/admin/caller_ids/new.vue?macro=true";
import { default as caller_idsq8XKrWN0zMMeta } from "/codebuild/output/src2320306735/src/pages/admin/caller_ids.vue?macro=true";
import { default as editYFZ6CZNSmdMeta } from "/codebuild/output/src2320306735/src/pages/admin/campaign_settings/[campaign_setting_id]/edit.vue?macro=true";
import { default as index1Qq5t3hmMmMeta } from "/codebuild/output/src2320306735/src/pages/admin/campaign_settings/[campaign_setting_id]/index.vue?macro=true";
import { default as _91campaign_setting_id_938p4skbPwWIMeta } from "/codebuild/output/src2320306735/src/pages/admin/campaign_settings/[campaign_setting_id].vue?macro=true";
import { default as editBzNCjQanGoMeta } from "/codebuild/output/src2320306735/src/pages/admin/campaigns/[campaign_id]/edit.vue?macro=true";
import { default as indexVmrtOV2v33Meta } from "/codebuild/output/src2320306735/src/pages/admin/campaigns/[campaign_id]/index.vue?macro=true";
import { default as editZZJwHYR6haMeta } from "/codebuild/output/src2320306735/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/[outbound_call_transaction_id]/edit.vue?macro=true";
import { default as indexCPEbzaeUJHMeta } from "/codebuild/output/src2320306735/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/[outbound_call_transaction_id]/index.vue?macro=true";
import { default as _91outbound_call_transaction_id_93OVp4xDTyszMeta } from "/codebuild/output/src2320306735/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/[outbound_call_transaction_id].vue?macro=true";
import { default as indexrwNcb2eMQlMeta } from "/codebuild/output/src2320306735/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/index.vue?macro=true";
import { default as newPghIVepLLYMeta } from "/codebuild/output/src2320306735/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/new.vue?macro=true";
import { default as outbound_call_transactionsfVe9Z5rnvqMeta } from "/codebuild/output/src2320306735/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions.vue?macro=true";
import { default as _91campaign_id_93bHH4UA5x7HMeta } from "/codebuild/output/src2320306735/src/pages/admin/campaigns/[campaign_id].vue?macro=true";
import { default as indexuvG3BeQaMyMeta } from "/codebuild/output/src2320306735/src/pages/admin/campaigns/index.vue?macro=true";
import { default as newtLeNBx5OonMeta } from "/codebuild/output/src2320306735/src/pages/admin/campaigns/new.vue?macro=true";
import { default as campaignsV1Nqt2WpxpMeta } from "/codebuild/output/src2320306735/src/pages/admin/campaigns.vue?macro=true";
import { default as indexi3Kv8BnlSTMeta } from "/codebuild/output/src2320306735/src/pages/admin/custom_emails/[custom_email_id]/index.vue?macro=true";
import { default as _91custom_email_id_93a0zFusj2GiMeta } from "/codebuild/output/src2320306735/src/pages/admin/custom_emails/[custom_email_id].vue?macro=true";
import { default as indexwq9FT4p0cBMeta } from "/codebuild/output/src2320306735/src/pages/admin/custom_emails/index.vue?macro=true";
import { default as newiRBfmBPhoPMeta } from "/codebuild/output/src2320306735/src/pages/admin/custom_emails/new.vue?macro=true";
import { default as custom_emailsbEKXQILGHfMeta } from "/codebuild/output/src2320306735/src/pages/admin/custom_emails.vue?macro=true";
import { default as indexXsklmo0X66Meta } from "/codebuild/output/src2320306735/src/pages/admin/dashboard/index.vue?macro=true";
import { default as dashboardAlzVyop8NLMeta } from "/codebuild/output/src2320306735/src/pages/admin/dashboard.vue?macro=true";
import { default as indexY4C0eD6eZjMeta } from "/codebuild/output/src2320306735/src/pages/admin/inquiries/[inquiry_id]/index.vue?macro=true";
import { default as _91inquiry_id_93tbrLKgX3ZsMeta } from "/codebuild/output/src2320306735/src/pages/admin/inquiries/[inquiry_id].vue?macro=true";
import { default as indexywOngzurK7Meta } from "/codebuild/output/src2320306735/src/pages/admin/inquiries/index.vue?macro=true";
import { default as newVtgN8PSZ9vMeta } from "/codebuild/output/src2320306735/src/pages/admin/inquiries/new.vue?macro=true";
import { default as inquiriesHYK6PBBqZcMeta } from "/codebuild/output/src2320306735/src/pages/admin/inquiries.vue?macro=true";
import { default as indexaU5lKfNqzcMeta } from "/codebuild/output/src2320306735/src/pages/admin/login/index.vue?macro=true";
import { default as loginNDpAydLQezMeta } from "/codebuild/output/src2320306735/src/pages/admin/login.vue?macro=true";
import { default as newaIenBrf5FNMeta } from "/codebuild/output/src2320306735/src/pages/admin/password/new.vue?macro=true";
import { default as indexmaShbm8ejMMeta } from "/codebuild/output/src2320306735/src/pages/admin/reports/index.vue?macro=true";
import { default as reportsJiep668uDZMeta } from "/codebuild/output/src2320306735/src/pages/admin/reports.vue?macro=true";
import { default as editv7E0Pz4xSRMeta } from "/codebuild/output/src2320306735/src/pages/admin/sms_maskings/[sms_masking_id]/edit.vue?macro=true";
import { default as indexnY4a5A4zYXMeta } from "/codebuild/output/src2320306735/src/pages/admin/sms_maskings/[sms_masking_id]/index.vue?macro=true";
import { default as _91sms_masking_id_93cc8cTu25DdMeta } from "/codebuild/output/src2320306735/src/pages/admin/sms_maskings/[sms_masking_id].vue?macro=true";
import { default as indexjHc6vx0gVyMeta } from "/codebuild/output/src2320306735/src/pages/admin/sms_maskings/index.vue?macro=true";
import { default as newK7DCzJ0vokMeta } from "/codebuild/output/src2320306735/src/pages/admin/sms_maskings/new.vue?macro=true";
import { default as sms_maskingss70zb1ShbGMeta } from "/codebuild/output/src2320306735/src/pages/admin/sms_maskings.vue?macro=true";
import { default as edit9WdC22zvqqMeta } from "/codebuild/output/src2320306735/src/pages/admin/users/[user_id]/edit.vue?macro=true";
import { default as indexc9tmPsvWrkMeta } from "/codebuild/output/src2320306735/src/pages/admin/users/[user_id]/index.vue?macro=true";
import { default as _91user_id_93FG0Axy6QVOMeta } from "/codebuild/output/src2320306735/src/pages/admin/users/[user_id].vue?macro=true";
import { default as indexz2qUbQyhy3Meta } from "/codebuild/output/src2320306735/src/pages/admin/users/index.vue?macro=true";
import { default as newclyTSgGs7WMeta } from "/codebuild/output/src2320306735/src/pages/admin/users/new.vue?macro=true";
import { default as usersI9hCr0z1v0Meta } from "/codebuild/output/src2320306735/src/pages/admin/users.vue?macro=true";
import { default as editxOYUl95yVNMeta } from "/codebuild/output/src2320306735/src/pages/admin/wallets/[wallet_id]/edit.vue?macro=true";
import { default as indexeBVamQv8bSMeta } from "/codebuild/output/src2320306735/src/pages/admin/wallets/[wallet_id]/index.vue?macro=true";
import { default as _91wallet_id_93XgPeegmbOiMeta } from "/codebuild/output/src2320306735/src/pages/admin/wallets/[wallet_id].vue?macro=true";
import { default as admintQd147PHnZMeta } from "/codebuild/output/src2320306735/src/pages/admin.vue?macro=true";
import { default as indexnPHOQ4ztI5Meta } from "/codebuild/output/src2320306735/src/pages/audit_logs/index.vue?macro=true";
import { default as index5q2srhq6RmMeta } from "/codebuild/output/src2320306735/src/pages/billings/index.vue?macro=true";
import { default as indexdlZ6Tngxe9Meta } from "/codebuild/output/src2320306735/src/pages/call_out/reports/index.vue?macro=true";
import { default as _91id_936rd2KBu0hXMeta } from "/codebuild/output/src2320306735/src/pages/call_recordings/[id].vue?macro=true";
import { default as index3T36PtpumWMeta } from "/codebuild/output/src2320306735/src/pages/campaigns/[id]/index.vue?macro=true";
import { default as indexJcHLQqJyLRMeta } from "/codebuild/output/src2320306735/src/pages/campaigns/index.vue?macro=true";
import { default as index1NCRTrmS3iMeta } from "/codebuild/output/src2320306735/src/pages/dashboard/index.vue?macro=true";
import { default as indexpL9CMoLDU6Meta } from "/codebuild/output/src2320306735/src/pages/help/index.vue?macro=true";
import { default as index3sYrVATQ3AMeta } from "/codebuild/output/src2320306735/src/pages/index.vue?macro=true";
import { default as indexNLM5Sez1UTMeta } from "/codebuild/output/src2320306735/src/pages/inquiry/index.vue?macro=true";
import { default as editorUccsEUE5SyMeta } from "/codebuild/output/src2320306735/src/pages/ivr_trees/[id]/editor.vue?macro=true";
import { default as index0ouBfw12EnMeta } from "/codebuild/output/src2320306735/src/pages/ivr_trees/index.vue?macro=true";
import { default as verify_emailDEWmuYn0YnMeta } from "/codebuild/output/src2320306735/src/pages/profiles/[user_id]/verify_email.vue?macro=true";
import { default as welcome_dashboardKQtuWgVL0aMeta } from "/codebuild/output/src2320306735/src/pages/profiles/[user_id]/welcome_dashboard.vue?macro=true";
import { default as indexwX6vjl9WjvMeta } from "/codebuild/output/src2320306735/src/pages/profiles/index.vue?macro=true";
import { default as indexA9fBmCGEkDMeta } from "/codebuild/output/src2320306735/src/pages/sub_users/users/index.vue?macro=true";
import { default as indexlP0ZmCnY09Meta } from "/codebuild/output/src2320306735/src/pages/terms_and_conditions/index.vue?macro=true";
import { default as index8KY6WEMl0hMeta } from "/codebuild/output/src2320306735/src/pages/users/password/edit/index.vue?macro=true";
import { default as indexMD2rFjntXzMeta } from "/codebuild/output/src2320306735/src/pages/users/password/new/index.vue?macro=true";
import { default as indexcdimLKEzn7Meta } from "/codebuild/output/src2320306735/src/pages/users/sign_in/index.vue?macro=true";
export default [
  {
    name: admintQd147PHnZMeta?.name ?? "admin",
    path: admintQd147PHnZMeta?.path ?? "/admin",
    children: [
  {
    path: _91account_plan_id_938YUw7zwtrKMeta?.path ?? "account_plans/:account_plan_id()",
    children: [
  {
    name: editJawOqo8tvBMeta?.name ?? "admin-account_plans-account_plan_id-edit",
    path: editJawOqo8tvBMeta?.path ?? "edit",
    meta: editJawOqo8tvBMeta || {},
    alias: editJawOqo8tvBMeta?.alias || [],
    redirect: editJawOqo8tvBMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/account_plans/[account_plan_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexQ0l1QzzFVeMeta?.name ?? "admin-account_plans-account_plan_id",
    path: indexQ0l1QzzFVeMeta?.path ?? "",
    meta: indexQ0l1QzzFVeMeta || {},
    alias: indexQ0l1QzzFVeMeta?.alias || [],
    redirect: indexQ0l1QzzFVeMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/account_plans/[account_plan_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91account_plan_id_938YUw7zwtrKMeta?.name ?? undefined,
    meta: _91account_plan_id_938YUw7zwtrKMeta || {},
    alias: _91account_plan_id_938YUw7zwtrKMeta?.alias || [],
    redirect: _91account_plan_id_938YUw7zwtrKMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/account_plans/[account_plan_id].vue").then(m => m.default || m)
  },
  {
    path: account_requested_plansRdlIAdM90hMeta?.path ?? "account_requested_plans",
    children: [
  {
    path: _91account_requested_plan_id_93rKuB1RmwWvMeta?.path ?? ":account_requested_plan_id()",
    children: [
  {
    name: editc4BFJDDiLaMeta?.name ?? "admin-account_requested_plans-account_requested_plan_id-edit",
    path: editc4BFJDDiLaMeta?.path ?? "edit",
    meta: editc4BFJDDiLaMeta || {},
    alias: editc4BFJDDiLaMeta?.alias || [],
    redirect: editc4BFJDDiLaMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/account_requested_plans/[account_requested_plan_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexZDkIAJTZcxMeta?.name ?? "admin-account_requested_plans-account_requested_plan_id",
    path: indexZDkIAJTZcxMeta?.path ?? "",
    meta: indexZDkIAJTZcxMeta || {},
    alias: indexZDkIAJTZcxMeta?.alias || [],
    redirect: indexZDkIAJTZcxMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/account_requested_plans/[account_requested_plan_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91account_requested_plan_id_93rKuB1RmwWvMeta?.name ?? undefined,
    meta: _91account_requested_plan_id_93rKuB1RmwWvMeta || {},
    alias: _91account_requested_plan_id_93rKuB1RmwWvMeta?.alias || [],
    redirect: _91account_requested_plan_id_93rKuB1RmwWvMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/account_requested_plans/[account_requested_plan_id].vue").then(m => m.default || m)
  },
  {
    name: indexS8BfggGhg8Meta?.name ?? "admin-account_requested_plans",
    path: indexS8BfggGhg8Meta?.path ?? "",
    meta: indexS8BfggGhg8Meta || {},
    alias: indexS8BfggGhg8Meta?.alias || [],
    redirect: indexS8BfggGhg8Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/account_requested_plans/index.vue").then(m => m.default || m)
  },
  {
    name: newEuzEGp97UyMeta?.name ?? "admin-account_requested_plans-new",
    path: newEuzEGp97UyMeta?.path ?? "new",
    meta: newEuzEGp97UyMeta || {},
    alias: newEuzEGp97UyMeta?.alias || [],
    redirect: newEuzEGp97UyMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/account_requested_plans/new.vue").then(m => m.default || m)
  }
],
    name: account_requested_plansRdlIAdM90hMeta?.name ?? undefined,
    meta: account_requested_plansRdlIAdM90hMeta || {},
    alias: account_requested_plansRdlIAdM90hMeta?.alias || [],
    redirect: account_requested_plansRdlIAdM90hMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/account_requested_plans.vue").then(m => m.default || m)
  },
  {
    path: accounts046bmTCOadMeta?.path ?? "accounts",
    children: [
  {
    path: _91account_id_93bPRhITGWLdMeta?.path ?? ":account_id()",
    children: [
  {
    path: account_requested_plansD85u4OAr9WMeta?.path ?? "account_requested_plans",
    children: [
  {
    path: _91account_requested_plan_id_93c1Z9OxWadoMeta?.path ?? ":account_requested_plan_id()",
    children: [
  {
    name: editQimktRD7oRMeta?.name ?? "admin-accounts-account_id-account_requested_plans-account_requested_plan_id-edit",
    path: editQimktRD7oRMeta?.path ?? "edit",
    meta: editQimktRD7oRMeta || {},
    alias: editQimktRD7oRMeta?.alias || [],
    redirect: editQimktRD7oRMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/accounts/[account_id]/account_requested_plans/[account_requested_plan_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexMoLdsYlCRaMeta?.name ?? "admin-accounts-account_id-account_requested_plans-account_requested_plan_id",
    path: indexMoLdsYlCRaMeta?.path ?? "",
    meta: indexMoLdsYlCRaMeta || {},
    alias: indexMoLdsYlCRaMeta?.alias || [],
    redirect: indexMoLdsYlCRaMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/accounts/[account_id]/account_requested_plans/[account_requested_plan_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91account_requested_plan_id_93c1Z9OxWadoMeta?.name ?? undefined,
    meta: _91account_requested_plan_id_93c1Z9OxWadoMeta || {},
    alias: _91account_requested_plan_id_93c1Z9OxWadoMeta?.alias || [],
    redirect: _91account_requested_plan_id_93c1Z9OxWadoMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/accounts/[account_id]/account_requested_plans/[account_requested_plan_id].vue").then(m => m.default || m)
  },
  {
    name: index0D409IcruAMeta?.name ?? "admin-accounts-account_id-account_requested_plans",
    path: index0D409IcruAMeta?.path ?? "",
    meta: index0D409IcruAMeta || {},
    alias: index0D409IcruAMeta?.alias || [],
    redirect: index0D409IcruAMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/accounts/[account_id]/account_requested_plans/index.vue").then(m => m.default || m)
  },
  {
    name: newUit1j3PZdTMeta?.name ?? "admin-accounts-account_id-account_requested_plans-new",
    path: newUit1j3PZdTMeta?.path ?? "new",
    meta: newUit1j3PZdTMeta || {},
    alias: newUit1j3PZdTMeta?.alias || [],
    redirect: newUit1j3PZdTMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/accounts/[account_id]/account_requested_plans/new.vue").then(m => m.default || m)
  }
],
    name: account_requested_plansD85u4OAr9WMeta?.name ?? undefined,
    meta: account_requested_plansD85u4OAr9WMeta || {},
    alias: account_requested_plansD85u4OAr9WMeta?.alias || [],
    redirect: account_requested_plansD85u4OAr9WMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/accounts/[account_id]/account_requested_plans.vue").then(m => m.default || m)
  },
  {
    path: caller_ids68gMxMsVAcMeta?.path ?? "caller_ids",
    children: [
  {
    path: _91caller_id_id_93JJHglvhHgrMeta?.path ?? ":caller_id_id()",
    children: [
  {
    name: editQ8bWv7pfDgMeta?.name ?? "admin-accounts-account_id-caller_ids-caller_id_id-edit",
    path: editQ8bWv7pfDgMeta?.path ?? "edit",
    meta: editQ8bWv7pfDgMeta || {},
    alias: editQ8bWv7pfDgMeta?.alias || [],
    redirect: editQ8bWv7pfDgMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/accounts/[account_id]/caller_ids/[caller_id_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexcyb6c7oXBdMeta?.name ?? "admin-accounts-account_id-caller_ids-caller_id_id",
    path: indexcyb6c7oXBdMeta?.path ?? "",
    meta: indexcyb6c7oXBdMeta || {},
    alias: indexcyb6c7oXBdMeta?.alias || [],
    redirect: indexcyb6c7oXBdMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/accounts/[account_id]/caller_ids/[caller_id_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91caller_id_id_93JJHglvhHgrMeta?.name ?? undefined,
    meta: _91caller_id_id_93JJHglvhHgrMeta || {},
    alias: _91caller_id_id_93JJHglvhHgrMeta?.alias || [],
    redirect: _91caller_id_id_93JJHglvhHgrMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/accounts/[account_id]/caller_ids/[caller_id_id].vue").then(m => m.default || m)
  },
  {
    name: index1rZDs2tQMUMeta?.name ?? "admin-accounts-account_id-caller_ids",
    path: index1rZDs2tQMUMeta?.path ?? "",
    meta: index1rZDs2tQMUMeta || {},
    alias: index1rZDs2tQMUMeta?.alias || [],
    redirect: index1rZDs2tQMUMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/accounts/[account_id]/caller_ids/index.vue").then(m => m.default || m)
  },
  {
    name: newNeIahGmjsEMeta?.name ?? "admin-accounts-account_id-caller_ids-new",
    path: newNeIahGmjsEMeta?.path ?? "new",
    meta: newNeIahGmjsEMeta || {},
    alias: newNeIahGmjsEMeta?.alias || [],
    redirect: newNeIahGmjsEMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/accounts/[account_id]/caller_ids/new.vue").then(m => m.default || m)
  }
],
    name: caller_ids68gMxMsVAcMeta?.name ?? undefined,
    meta: caller_ids68gMxMsVAcMeta || {},
    alias: caller_ids68gMxMsVAcMeta?.alias || [],
    redirect: caller_ids68gMxMsVAcMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/accounts/[account_id]/caller_ids.vue").then(m => m.default || m)
  },
  {
    name: editu9khS16bTrMeta?.name ?? "admin-accounts-account_id-edit",
    path: editu9khS16bTrMeta?.path ?? "edit",
    meta: editu9khS16bTrMeta || {},
    alias: editu9khS16bTrMeta?.alias || [],
    redirect: editu9khS16bTrMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/accounts/[account_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexsyOhSbI0fMMeta?.name ?? "admin-accounts-account_id",
    path: indexsyOhSbI0fMMeta?.path ?? "",
    meta: indexsyOhSbI0fMMeta || {},
    alias: indexsyOhSbI0fMMeta?.alias || [],
    redirect: indexsyOhSbI0fMMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/accounts/[account_id]/index.vue").then(m => m.default || m)
  },
  {
    path: sms_maskingsp3dslZAsMqMeta?.path ?? "sms_maskings",
    children: [
  {
    path: _91sms_masking_id_93cVrQqla7vuMeta?.path ?? ":sms_masking_id()",
    children: [
  {
    name: editYf6HOH2wSIMeta?.name ?? "admin-accounts-account_id-sms_maskings-sms_masking_id-edit",
    path: editYf6HOH2wSIMeta?.path ?? "edit",
    meta: editYf6HOH2wSIMeta || {},
    alias: editYf6HOH2wSIMeta?.alias || [],
    redirect: editYf6HOH2wSIMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/accounts/[account_id]/sms_maskings/[sms_masking_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexIDrvCvHwlpMeta?.name ?? "admin-accounts-account_id-sms_maskings-sms_masking_id",
    path: indexIDrvCvHwlpMeta?.path ?? "",
    meta: indexIDrvCvHwlpMeta || {},
    alias: indexIDrvCvHwlpMeta?.alias || [],
    redirect: indexIDrvCvHwlpMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/accounts/[account_id]/sms_maskings/[sms_masking_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91sms_masking_id_93cVrQqla7vuMeta?.name ?? undefined,
    meta: _91sms_masking_id_93cVrQqla7vuMeta || {},
    alias: _91sms_masking_id_93cVrQqla7vuMeta?.alias || [],
    redirect: _91sms_masking_id_93cVrQqla7vuMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/accounts/[account_id]/sms_maskings/[sms_masking_id].vue").then(m => m.default || m)
  },
  {
    name: indexhqNYpl0BFRMeta?.name ?? "admin-accounts-account_id-sms_maskings",
    path: indexhqNYpl0BFRMeta?.path ?? "",
    meta: indexhqNYpl0BFRMeta || {},
    alias: indexhqNYpl0BFRMeta?.alias || [],
    redirect: indexhqNYpl0BFRMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/accounts/[account_id]/sms_maskings/index.vue").then(m => m.default || m)
  },
  {
    name: newnS3H5zLGcGMeta?.name ?? "admin-accounts-account_id-sms_maskings-new",
    path: newnS3H5zLGcGMeta?.path ?? "new",
    meta: newnS3H5zLGcGMeta || {},
    alias: newnS3H5zLGcGMeta?.alias || [],
    redirect: newnS3H5zLGcGMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/accounts/[account_id]/sms_maskings/new.vue").then(m => m.default || m)
  }
],
    name: sms_maskingsp3dslZAsMqMeta?.name ?? undefined,
    meta: sms_maskingsp3dslZAsMqMeta || {},
    alias: sms_maskingsp3dslZAsMqMeta?.alias || [],
    redirect: sms_maskingsp3dslZAsMqMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/accounts/[account_id]/sms_maskings.vue").then(m => m.default || m)
  }
],
    name: _91account_id_93bPRhITGWLdMeta?.name ?? undefined,
    meta: _91account_id_93bPRhITGWLdMeta || {},
    alias: _91account_id_93bPRhITGWLdMeta?.alias || [],
    redirect: _91account_id_93bPRhITGWLdMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/accounts/[account_id].vue").then(m => m.default || m)
  },
  {
    name: indexT2JqUW5unjMeta?.name ?? "admin-accounts",
    path: indexT2JqUW5unjMeta?.path ?? "",
    meta: indexT2JqUW5unjMeta || {},
    alias: indexT2JqUW5unjMeta?.alias || [],
    redirect: indexT2JqUW5unjMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/accounts/index.vue").then(m => m.default || m)
  },
  {
    name: wizard_plan_selectionL10hDqfqoEMeta?.name ?? "admin-accounts-wizard_plan_selection",
    path: wizard_plan_selectionL10hDqfqoEMeta?.path ?? "wizard_plan_selection",
    meta: wizard_plan_selectionL10hDqfqoEMeta || {},
    alias: wizard_plan_selectionL10hDqfqoEMeta?.alias || [],
    redirect: wizard_plan_selectionL10hDqfqoEMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/accounts/wizard_plan_selection.vue").then(m => m.default || m)
  },
  {
    name: wizardt1nMz2eNJiMeta?.name ?? "admin-accounts-wizard",
    path: wizardt1nMz2eNJiMeta?.path ?? "wizard",
    meta: wizardt1nMz2eNJiMeta || {},
    alias: wizardt1nMz2eNJiMeta?.alias || [],
    redirect: wizardt1nMz2eNJiMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/accounts/wizard.vue").then(m => m.default || m)
  }
],
    name: accounts046bmTCOadMeta?.name ?? undefined,
    meta: accounts046bmTCOadMeta || {},
    alias: accounts046bmTCOadMeta?.alias || [],
    redirect: accounts046bmTCOadMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/accounts.vue").then(m => m.default || m)
  },
  {
    path: admin_userscnUorn9n82Meta?.path ?? "admin_users",
    children: [
  {
    path: _91admin_user_id_93EMGUbhmxcEMeta?.path ?? ":admin_user_id()",
    children: [
  {
    name: editYEl4ymv0tNMeta?.name ?? "admin-admin_users-admin_user_id-edit",
    path: editYEl4ymv0tNMeta?.path ?? "edit",
    meta: editYEl4ymv0tNMeta || {},
    alias: editYEl4ymv0tNMeta?.alias || [],
    redirect: editYEl4ymv0tNMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/admin_users/[admin_user_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexrvVTpTCUmKMeta?.name ?? "admin-admin_users-admin_user_id",
    path: indexrvVTpTCUmKMeta?.path ?? "",
    meta: indexrvVTpTCUmKMeta || {},
    alias: indexrvVTpTCUmKMeta?.alias || [],
    redirect: indexrvVTpTCUmKMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/admin_users/[admin_user_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91admin_user_id_93EMGUbhmxcEMeta?.name ?? undefined,
    meta: _91admin_user_id_93EMGUbhmxcEMeta || {},
    alias: _91admin_user_id_93EMGUbhmxcEMeta?.alias || [],
    redirect: _91admin_user_id_93EMGUbhmxcEMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/admin_users/[admin_user_id].vue").then(m => m.default || m)
  },
  {
    name: index2w5EwIjTUTMeta?.name ?? "admin-admin_users",
    path: index2w5EwIjTUTMeta?.path ?? "",
    meta: index2w5EwIjTUTMeta || {},
    alias: index2w5EwIjTUTMeta?.alias || [],
    redirect: index2w5EwIjTUTMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/admin_users/index.vue").then(m => m.default || m)
  },
  {
    name: newwasJTAxzCwMeta?.name ?? "admin-admin_users-new",
    path: newwasJTAxzCwMeta?.path ?? "new",
    meta: newwasJTAxzCwMeta || {},
    alias: newwasJTAxzCwMeta?.alias || [],
    redirect: newwasJTAxzCwMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/admin_users/new.vue").then(m => m.default || m)
  }
],
    name: admin_userscnUorn9n82Meta?.name ?? undefined,
    meta: admin_userscnUorn9n82Meta || {},
    alias: admin_userscnUorn9n82Meta?.alias || [],
    redirect: admin_userscnUorn9n82Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/admin_users.vue").then(m => m.default || m)
  },
  {
    path: audit_trailsDJTiir9V9fMeta?.path ?? "audit_trails",
    children: [
  {
    name: indexNlP6vsJH69Meta?.name ?? "admin-audit_trails",
    path: indexNlP6vsJH69Meta?.path ?? "",
    meta: indexNlP6vsJH69Meta || {},
    alias: indexNlP6vsJH69Meta?.alias || [],
    redirect: indexNlP6vsJH69Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/audit_trails/index.vue").then(m => m.default || m)
  }
],
    name: audit_trailsDJTiir9V9fMeta?.name ?? undefined,
    meta: audit_trailsDJTiir9V9fMeta || {},
    alias: audit_trailsDJTiir9V9fMeta?.alias || [],
    redirect: audit_trailsDJTiir9V9fMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/audit_trails.vue").then(m => m.default || m)
  },
  {
    path: billingsVwrxOL8Df1Meta?.path ?? "billings",
    children: [
  {
    path: _91billing_id_93sRhyYobYmpMeta?.path ?? ":billing_id()",
    children: [
  {
    name: editTeWG4xvRfyMeta?.name ?? "admin-billings-billing_id-edit",
    path: editTeWG4xvRfyMeta?.path ?? "edit",
    meta: editTeWG4xvRfyMeta || {},
    alias: editTeWG4xvRfyMeta?.alias || [],
    redirect: editTeWG4xvRfyMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/billings/[billing_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: index6YxKWiRrXgMeta?.name ?? "admin-billings-billing_id",
    path: index6YxKWiRrXgMeta?.path ?? "",
    meta: index6YxKWiRrXgMeta || {},
    alias: index6YxKWiRrXgMeta?.alias || [],
    redirect: index6YxKWiRrXgMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/billings/[billing_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91billing_id_93sRhyYobYmpMeta?.name ?? undefined,
    meta: _91billing_id_93sRhyYobYmpMeta || {},
    alias: _91billing_id_93sRhyYobYmpMeta?.alias || [],
    redirect: _91billing_id_93sRhyYobYmpMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/billings/[billing_id].vue").then(m => m.default || m)
  },
  {
    name: indexe4V2qfwhdzMeta?.name ?? "admin-billings",
    path: indexe4V2qfwhdzMeta?.path ?? "",
    meta: indexe4V2qfwhdzMeta || {},
    alias: indexe4V2qfwhdzMeta?.alias || [],
    redirect: indexe4V2qfwhdzMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/billings/index.vue").then(m => m.default || m)
  },
  {
    name: newYaxl1QwYnHMeta?.name ?? "admin-billings-new",
    path: newYaxl1QwYnHMeta?.path ?? "new",
    meta: newYaxl1QwYnHMeta || {},
    alias: newYaxl1QwYnHMeta?.alias || [],
    redirect: newYaxl1QwYnHMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/billings/new.vue").then(m => m.default || m)
  }
],
    name: billingsVwrxOL8Df1Meta?.name ?? undefined,
    meta: billingsVwrxOL8Df1Meta || {},
    alias: billingsVwrxOL8Df1Meta?.alias || [],
    redirect: billingsVwrxOL8Df1Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/billings.vue").then(m => m.default || m)
  },
  {
    path: caller_idsq8XKrWN0zMMeta?.path ?? "caller_ids",
    children: [
  {
    path: _91caller_id_id_93WJhbBclO0AMeta?.path ?? ":caller_id_id()",
    children: [
  {
    name: editPidNaLJZ89Meta?.name ?? "admin-caller_ids-caller_id_id-edit",
    path: editPidNaLJZ89Meta?.path ?? "edit",
    meta: editPidNaLJZ89Meta || {},
    alias: editPidNaLJZ89Meta?.alias || [],
    redirect: editPidNaLJZ89Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/caller_ids/[caller_id_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexqck7sY0XNfMeta?.name ?? "admin-caller_ids-caller_id_id",
    path: indexqck7sY0XNfMeta?.path ?? "",
    meta: indexqck7sY0XNfMeta || {},
    alias: indexqck7sY0XNfMeta?.alias || [],
    redirect: indexqck7sY0XNfMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/caller_ids/[caller_id_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91caller_id_id_93WJhbBclO0AMeta?.name ?? undefined,
    meta: _91caller_id_id_93WJhbBclO0AMeta || {},
    alias: _91caller_id_id_93WJhbBclO0AMeta?.alias || [],
    redirect: _91caller_id_id_93WJhbBclO0AMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/caller_ids/[caller_id_id].vue").then(m => m.default || m)
  },
  {
    name: index2pgyAOSt39Meta?.name ?? "admin-caller_ids",
    path: index2pgyAOSt39Meta?.path ?? "",
    meta: index2pgyAOSt39Meta || {},
    alias: index2pgyAOSt39Meta?.alias || [],
    redirect: index2pgyAOSt39Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/caller_ids/index.vue").then(m => m.default || m)
  },
  {
    name: newUWkiQhODdqMeta?.name ?? "admin-caller_ids-new",
    path: newUWkiQhODdqMeta?.path ?? "new",
    meta: newUWkiQhODdqMeta || {},
    alias: newUWkiQhODdqMeta?.alias || [],
    redirect: newUWkiQhODdqMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/caller_ids/new.vue").then(m => m.default || m)
  }
],
    name: caller_idsq8XKrWN0zMMeta?.name ?? undefined,
    meta: caller_idsq8XKrWN0zMMeta || {},
    alias: caller_idsq8XKrWN0zMMeta?.alias || [],
    redirect: caller_idsq8XKrWN0zMMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/caller_ids.vue").then(m => m.default || m)
  },
  {
    path: _91campaign_setting_id_938p4skbPwWIMeta?.path ?? "campaign_settings/:campaign_setting_id()",
    children: [
  {
    name: editYFZ6CZNSmdMeta?.name ?? "admin-campaign_settings-campaign_setting_id-edit",
    path: editYFZ6CZNSmdMeta?.path ?? "edit",
    meta: editYFZ6CZNSmdMeta || {},
    alias: editYFZ6CZNSmdMeta?.alias || [],
    redirect: editYFZ6CZNSmdMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/campaign_settings/[campaign_setting_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: index1Qq5t3hmMmMeta?.name ?? "admin-campaign_settings-campaign_setting_id",
    path: index1Qq5t3hmMmMeta?.path ?? "",
    meta: index1Qq5t3hmMmMeta || {},
    alias: index1Qq5t3hmMmMeta?.alias || [],
    redirect: index1Qq5t3hmMmMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/campaign_settings/[campaign_setting_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91campaign_setting_id_938p4skbPwWIMeta?.name ?? undefined,
    meta: _91campaign_setting_id_938p4skbPwWIMeta || {},
    alias: _91campaign_setting_id_938p4skbPwWIMeta?.alias || [],
    redirect: _91campaign_setting_id_938p4skbPwWIMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/campaign_settings/[campaign_setting_id].vue").then(m => m.default || m)
  },
  {
    path: campaignsV1Nqt2WpxpMeta?.path ?? "campaigns",
    children: [
  {
    path: _91campaign_id_93bHH4UA5x7HMeta?.path ?? ":campaign_id()",
    children: [
  {
    name: editBzNCjQanGoMeta?.name ?? "admin-campaigns-campaign_id-edit",
    path: editBzNCjQanGoMeta?.path ?? "edit",
    meta: editBzNCjQanGoMeta || {},
    alias: editBzNCjQanGoMeta?.alias || [],
    redirect: editBzNCjQanGoMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/campaigns/[campaign_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexVmrtOV2v33Meta?.name ?? "admin-campaigns-campaign_id",
    path: indexVmrtOV2v33Meta?.path ?? "",
    meta: indexVmrtOV2v33Meta || {},
    alias: indexVmrtOV2v33Meta?.alias || [],
    redirect: indexVmrtOV2v33Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/campaigns/[campaign_id]/index.vue").then(m => m.default || m)
  },
  {
    path: outbound_call_transactionsfVe9Z5rnvqMeta?.path ?? "outbound_call_transactions",
    children: [
  {
    path: _91outbound_call_transaction_id_93OVp4xDTyszMeta?.path ?? ":outbound_call_transaction_id()",
    children: [
  {
    name: editZZJwHYR6haMeta?.name ?? "admin-campaigns-campaign_id-outbound_call_transactions-outbound_call_transaction_id-edit",
    path: editZZJwHYR6haMeta?.path ?? "edit",
    meta: editZZJwHYR6haMeta || {},
    alias: editZZJwHYR6haMeta?.alias || [],
    redirect: editZZJwHYR6haMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/[outbound_call_transaction_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexCPEbzaeUJHMeta?.name ?? "admin-campaigns-campaign_id-outbound_call_transactions-outbound_call_transaction_id",
    path: indexCPEbzaeUJHMeta?.path ?? "",
    meta: indexCPEbzaeUJHMeta || {},
    alias: indexCPEbzaeUJHMeta?.alias || [],
    redirect: indexCPEbzaeUJHMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/[outbound_call_transaction_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91outbound_call_transaction_id_93OVp4xDTyszMeta?.name ?? undefined,
    meta: _91outbound_call_transaction_id_93OVp4xDTyszMeta || {},
    alias: _91outbound_call_transaction_id_93OVp4xDTyszMeta?.alias || [],
    redirect: _91outbound_call_transaction_id_93OVp4xDTyszMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/[outbound_call_transaction_id].vue").then(m => m.default || m)
  },
  {
    name: indexrwNcb2eMQlMeta?.name ?? "admin-campaigns-campaign_id-outbound_call_transactions",
    path: indexrwNcb2eMQlMeta?.path ?? "",
    meta: indexrwNcb2eMQlMeta || {},
    alias: indexrwNcb2eMQlMeta?.alias || [],
    redirect: indexrwNcb2eMQlMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/index.vue").then(m => m.default || m)
  },
  {
    name: newPghIVepLLYMeta?.name ?? "admin-campaigns-campaign_id-outbound_call_transactions-new",
    path: newPghIVepLLYMeta?.path ?? "new",
    meta: newPghIVepLLYMeta || {},
    alias: newPghIVepLLYMeta?.alias || [],
    redirect: newPghIVepLLYMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/new.vue").then(m => m.default || m)
  }
],
    name: outbound_call_transactionsfVe9Z5rnvqMeta?.name ?? undefined,
    meta: outbound_call_transactionsfVe9Z5rnvqMeta || {},
    alias: outbound_call_transactionsfVe9Z5rnvqMeta?.alias || [],
    redirect: outbound_call_transactionsfVe9Z5rnvqMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions.vue").then(m => m.default || m)
  }
],
    name: _91campaign_id_93bHH4UA5x7HMeta?.name ?? undefined,
    meta: _91campaign_id_93bHH4UA5x7HMeta || {},
    alias: _91campaign_id_93bHH4UA5x7HMeta?.alias || [],
    redirect: _91campaign_id_93bHH4UA5x7HMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/campaigns/[campaign_id].vue").then(m => m.default || m)
  },
  {
    name: indexuvG3BeQaMyMeta?.name ?? "admin-campaigns",
    path: indexuvG3BeQaMyMeta?.path ?? "",
    meta: indexuvG3BeQaMyMeta || {},
    alias: indexuvG3BeQaMyMeta?.alias || [],
    redirect: indexuvG3BeQaMyMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/campaigns/index.vue").then(m => m.default || m)
  },
  {
    name: newtLeNBx5OonMeta?.name ?? "admin-campaigns-new",
    path: newtLeNBx5OonMeta?.path ?? "new",
    meta: newtLeNBx5OonMeta || {},
    alias: newtLeNBx5OonMeta?.alias || [],
    redirect: newtLeNBx5OonMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/campaigns/new.vue").then(m => m.default || m)
  }
],
    name: campaignsV1Nqt2WpxpMeta?.name ?? undefined,
    meta: campaignsV1Nqt2WpxpMeta || {},
    alias: campaignsV1Nqt2WpxpMeta?.alias || [],
    redirect: campaignsV1Nqt2WpxpMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/campaigns.vue").then(m => m.default || m)
  },
  {
    path: custom_emailsbEKXQILGHfMeta?.path ?? "custom_emails",
    children: [
  {
    path: _91custom_email_id_93a0zFusj2GiMeta?.path ?? ":custom_email_id()",
    children: [
  {
    name: indexi3Kv8BnlSTMeta?.name ?? "admin-custom_emails-custom_email_id",
    path: indexi3Kv8BnlSTMeta?.path ?? "",
    meta: indexi3Kv8BnlSTMeta || {},
    alias: indexi3Kv8BnlSTMeta?.alias || [],
    redirect: indexi3Kv8BnlSTMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/custom_emails/[custom_email_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91custom_email_id_93a0zFusj2GiMeta?.name ?? undefined,
    meta: _91custom_email_id_93a0zFusj2GiMeta || {},
    alias: _91custom_email_id_93a0zFusj2GiMeta?.alias || [],
    redirect: _91custom_email_id_93a0zFusj2GiMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/custom_emails/[custom_email_id].vue").then(m => m.default || m)
  },
  {
    name: indexwq9FT4p0cBMeta?.name ?? "admin-custom_emails",
    path: indexwq9FT4p0cBMeta?.path ?? "",
    meta: indexwq9FT4p0cBMeta || {},
    alias: indexwq9FT4p0cBMeta?.alias || [],
    redirect: indexwq9FT4p0cBMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/custom_emails/index.vue").then(m => m.default || m)
  },
  {
    name: newiRBfmBPhoPMeta?.name ?? "admin-custom_emails-new",
    path: newiRBfmBPhoPMeta?.path ?? "new",
    meta: newiRBfmBPhoPMeta || {},
    alias: newiRBfmBPhoPMeta?.alias || [],
    redirect: newiRBfmBPhoPMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/custom_emails/new.vue").then(m => m.default || m)
  }
],
    name: custom_emailsbEKXQILGHfMeta?.name ?? undefined,
    meta: custom_emailsbEKXQILGHfMeta || {},
    alias: custom_emailsbEKXQILGHfMeta?.alias || [],
    redirect: custom_emailsbEKXQILGHfMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/custom_emails.vue").then(m => m.default || m)
  },
  {
    path: dashboardAlzVyop8NLMeta?.path ?? "dashboard",
    children: [
  {
    name: indexXsklmo0X66Meta?.name ?? "admin-dashboard",
    path: indexXsklmo0X66Meta?.path ?? "",
    meta: indexXsklmo0X66Meta || {},
    alias: indexXsklmo0X66Meta?.alias || [],
    redirect: indexXsklmo0X66Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/dashboard/index.vue").then(m => m.default || m)
  }
],
    name: dashboardAlzVyop8NLMeta?.name ?? undefined,
    meta: dashboardAlzVyop8NLMeta || {},
    alias: dashboardAlzVyop8NLMeta?.alias || [],
    redirect: dashboardAlzVyop8NLMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/dashboard.vue").then(m => m.default || m)
  },
  {
    path: inquiriesHYK6PBBqZcMeta?.path ?? "inquiries",
    children: [
  {
    path: _91inquiry_id_93tbrLKgX3ZsMeta?.path ?? ":inquiry_id()",
    children: [
  {
    name: indexY4C0eD6eZjMeta?.name ?? "admin-inquiries-inquiry_id",
    path: indexY4C0eD6eZjMeta?.path ?? "",
    meta: indexY4C0eD6eZjMeta || {},
    alias: indexY4C0eD6eZjMeta?.alias || [],
    redirect: indexY4C0eD6eZjMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/inquiries/[inquiry_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91inquiry_id_93tbrLKgX3ZsMeta?.name ?? undefined,
    meta: _91inquiry_id_93tbrLKgX3ZsMeta || {},
    alias: _91inquiry_id_93tbrLKgX3ZsMeta?.alias || [],
    redirect: _91inquiry_id_93tbrLKgX3ZsMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/inquiries/[inquiry_id].vue").then(m => m.default || m)
  },
  {
    name: indexywOngzurK7Meta?.name ?? "admin-inquiries",
    path: indexywOngzurK7Meta?.path ?? "",
    meta: indexywOngzurK7Meta || {},
    alias: indexywOngzurK7Meta?.alias || [],
    redirect: indexywOngzurK7Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/inquiries/index.vue").then(m => m.default || m)
  },
  {
    name: newVtgN8PSZ9vMeta?.name ?? "admin-inquiries-new",
    path: newVtgN8PSZ9vMeta?.path ?? "new",
    meta: newVtgN8PSZ9vMeta || {},
    alias: newVtgN8PSZ9vMeta?.alias || [],
    redirect: newVtgN8PSZ9vMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/inquiries/new.vue").then(m => m.default || m)
  }
],
    name: inquiriesHYK6PBBqZcMeta?.name ?? undefined,
    meta: inquiriesHYK6PBBqZcMeta || {},
    alias: inquiriesHYK6PBBqZcMeta?.alias || [],
    redirect: inquiriesHYK6PBBqZcMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/inquiries.vue").then(m => m.default || m)
  },
  {
    path: loginNDpAydLQezMeta?.path ?? "login",
    children: [
  {
    name: indexaU5lKfNqzcMeta?.name ?? "admin-login",
    path: indexaU5lKfNqzcMeta?.path ?? "",
    meta: indexaU5lKfNqzcMeta || {},
    alias: indexaU5lKfNqzcMeta?.alias || [],
    redirect: indexaU5lKfNqzcMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/login/index.vue").then(m => m.default || m)
  }
],
    name: loginNDpAydLQezMeta?.name ?? undefined,
    meta: loginNDpAydLQezMeta || {},
    alias: loginNDpAydLQezMeta?.alias || [],
    redirect: loginNDpAydLQezMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/login.vue").then(m => m.default || m)
  },
  {
    name: newaIenBrf5FNMeta?.name ?? "admin-password-new",
    path: newaIenBrf5FNMeta?.path ?? "password/new",
    meta: newaIenBrf5FNMeta || {},
    alias: newaIenBrf5FNMeta?.alias || [],
    redirect: newaIenBrf5FNMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/password/new.vue").then(m => m.default || m)
  },
  {
    path: reportsJiep668uDZMeta?.path ?? "reports",
    children: [
  {
    name: indexmaShbm8ejMMeta?.name ?? "admin-reports",
    path: indexmaShbm8ejMMeta?.path ?? "",
    meta: indexmaShbm8ejMMeta || {},
    alias: indexmaShbm8ejMMeta?.alias || [],
    redirect: indexmaShbm8ejMMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/reports/index.vue").then(m => m.default || m)
  }
],
    name: reportsJiep668uDZMeta?.name ?? undefined,
    meta: reportsJiep668uDZMeta || {},
    alias: reportsJiep668uDZMeta?.alias || [],
    redirect: reportsJiep668uDZMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/reports.vue").then(m => m.default || m)
  },
  {
    path: sms_maskingss70zb1ShbGMeta?.path ?? "sms_maskings",
    children: [
  {
    path: _91sms_masking_id_93cc8cTu25DdMeta?.path ?? ":sms_masking_id()",
    children: [
  {
    name: editv7E0Pz4xSRMeta?.name ?? "admin-sms_maskings-sms_masking_id-edit",
    path: editv7E0Pz4xSRMeta?.path ?? "edit",
    meta: editv7E0Pz4xSRMeta || {},
    alias: editv7E0Pz4xSRMeta?.alias || [],
    redirect: editv7E0Pz4xSRMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/sms_maskings/[sms_masking_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexnY4a5A4zYXMeta?.name ?? "admin-sms_maskings-sms_masking_id",
    path: indexnY4a5A4zYXMeta?.path ?? "",
    meta: indexnY4a5A4zYXMeta || {},
    alias: indexnY4a5A4zYXMeta?.alias || [],
    redirect: indexnY4a5A4zYXMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/sms_maskings/[sms_masking_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91sms_masking_id_93cc8cTu25DdMeta?.name ?? undefined,
    meta: _91sms_masking_id_93cc8cTu25DdMeta || {},
    alias: _91sms_masking_id_93cc8cTu25DdMeta?.alias || [],
    redirect: _91sms_masking_id_93cc8cTu25DdMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/sms_maskings/[sms_masking_id].vue").then(m => m.default || m)
  },
  {
    name: indexjHc6vx0gVyMeta?.name ?? "admin-sms_maskings",
    path: indexjHc6vx0gVyMeta?.path ?? "",
    meta: indexjHc6vx0gVyMeta || {},
    alias: indexjHc6vx0gVyMeta?.alias || [],
    redirect: indexjHc6vx0gVyMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/sms_maskings/index.vue").then(m => m.default || m)
  },
  {
    name: newK7DCzJ0vokMeta?.name ?? "admin-sms_maskings-new",
    path: newK7DCzJ0vokMeta?.path ?? "new",
    meta: newK7DCzJ0vokMeta || {},
    alias: newK7DCzJ0vokMeta?.alias || [],
    redirect: newK7DCzJ0vokMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/sms_maskings/new.vue").then(m => m.default || m)
  }
],
    name: sms_maskingss70zb1ShbGMeta?.name ?? undefined,
    meta: sms_maskingss70zb1ShbGMeta || {},
    alias: sms_maskingss70zb1ShbGMeta?.alias || [],
    redirect: sms_maskingss70zb1ShbGMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/sms_maskings.vue").then(m => m.default || m)
  },
  {
    path: usersI9hCr0z1v0Meta?.path ?? "users",
    children: [
  {
    path: _91user_id_93FG0Axy6QVOMeta?.path ?? ":user_id()",
    children: [
  {
    name: edit9WdC22zvqqMeta?.name ?? "admin-users-user_id-edit",
    path: edit9WdC22zvqqMeta?.path ?? "edit",
    meta: edit9WdC22zvqqMeta || {},
    alias: edit9WdC22zvqqMeta?.alias || [],
    redirect: edit9WdC22zvqqMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/users/[user_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexc9tmPsvWrkMeta?.name ?? "admin-users-user_id",
    path: indexc9tmPsvWrkMeta?.path ?? "",
    meta: indexc9tmPsvWrkMeta || {},
    alias: indexc9tmPsvWrkMeta?.alias || [],
    redirect: indexc9tmPsvWrkMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/users/[user_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91user_id_93FG0Axy6QVOMeta?.name ?? undefined,
    meta: _91user_id_93FG0Axy6QVOMeta || {},
    alias: _91user_id_93FG0Axy6QVOMeta?.alias || [],
    redirect: _91user_id_93FG0Axy6QVOMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/users/[user_id].vue").then(m => m.default || m)
  },
  {
    name: indexz2qUbQyhy3Meta?.name ?? "admin-users",
    path: indexz2qUbQyhy3Meta?.path ?? "",
    meta: indexz2qUbQyhy3Meta || {},
    alias: indexz2qUbQyhy3Meta?.alias || [],
    redirect: indexz2qUbQyhy3Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/users/index.vue").then(m => m.default || m)
  },
  {
    name: newclyTSgGs7WMeta?.name ?? "admin-users-new",
    path: newclyTSgGs7WMeta?.path ?? "new",
    meta: newclyTSgGs7WMeta || {},
    alias: newclyTSgGs7WMeta?.alias || [],
    redirect: newclyTSgGs7WMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/users/new.vue").then(m => m.default || m)
  }
],
    name: usersI9hCr0z1v0Meta?.name ?? undefined,
    meta: usersI9hCr0z1v0Meta || {},
    alias: usersI9hCr0z1v0Meta?.alias || [],
    redirect: usersI9hCr0z1v0Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/users.vue").then(m => m.default || m)
  },
  {
    path: _91wallet_id_93XgPeegmbOiMeta?.path ?? "wallets/:wallet_id()",
    children: [
  {
    name: editxOYUl95yVNMeta?.name ?? "admin-wallets-wallet_id-edit",
    path: editxOYUl95yVNMeta?.path ?? "edit",
    meta: editxOYUl95yVNMeta || {},
    alias: editxOYUl95yVNMeta?.alias || [],
    redirect: editxOYUl95yVNMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/wallets/[wallet_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexeBVamQv8bSMeta?.name ?? "admin-wallets-wallet_id",
    path: indexeBVamQv8bSMeta?.path ?? "",
    meta: indexeBVamQv8bSMeta || {},
    alias: indexeBVamQv8bSMeta?.alias || [],
    redirect: indexeBVamQv8bSMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/wallets/[wallet_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91wallet_id_93XgPeegmbOiMeta?.name ?? undefined,
    meta: _91wallet_id_93XgPeegmbOiMeta || {},
    alias: _91wallet_id_93XgPeegmbOiMeta?.alias || [],
    redirect: _91wallet_id_93XgPeegmbOiMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin/wallets/[wallet_id].vue").then(m => m.default || m)
  }
],
    meta: admintQd147PHnZMeta || {},
    alias: admintQd147PHnZMeta?.alias || [],
    redirect: admintQd147PHnZMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/admin.vue").then(m => m.default || m)
  },
  {
    name: indexnPHOQ4ztI5Meta?.name ?? "audit_logs",
    path: indexnPHOQ4ztI5Meta?.path ?? "/audit_logs",
    meta: indexnPHOQ4ztI5Meta || {},
    alias: indexnPHOQ4ztI5Meta?.alias || [],
    redirect: indexnPHOQ4ztI5Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/audit_logs/index.vue").then(m => m.default || m)
  },
  {
    name: index5q2srhq6RmMeta?.name ?? "billings",
    path: index5q2srhq6RmMeta?.path ?? "/billings",
    meta: index5q2srhq6RmMeta || {},
    alias: index5q2srhq6RmMeta?.alias || [],
    redirect: index5q2srhq6RmMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/billings/index.vue").then(m => m.default || m)
  },
  {
    name: indexdlZ6Tngxe9Meta?.name ?? "call_out-reports",
    path: indexdlZ6Tngxe9Meta?.path ?? "/call_out/reports",
    meta: indexdlZ6Tngxe9Meta || {},
    alias: indexdlZ6Tngxe9Meta?.alias || [],
    redirect: indexdlZ6Tngxe9Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/call_out/reports/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_936rd2KBu0hXMeta?.name ?? "call_recordings-id",
    path: _91id_936rd2KBu0hXMeta?.path ?? "/call_recordings/:id()",
    meta: _91id_936rd2KBu0hXMeta || {},
    alias: _91id_936rd2KBu0hXMeta?.alias || [],
    redirect: _91id_936rd2KBu0hXMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/call_recordings/[id].vue").then(m => m.default || m)
  },
  {
    name: index3T36PtpumWMeta?.name ?? "campaigns-id",
    path: index3T36PtpumWMeta?.path ?? "/campaigns/:id()",
    meta: index3T36PtpumWMeta || {},
    alias: index3T36PtpumWMeta?.alias || [],
    redirect: index3T36PtpumWMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/campaigns/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexJcHLQqJyLRMeta?.name ?? "campaigns",
    path: indexJcHLQqJyLRMeta?.path ?? "/campaigns",
    meta: indexJcHLQqJyLRMeta || {},
    alias: indexJcHLQqJyLRMeta?.alias || [],
    redirect: indexJcHLQqJyLRMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/campaigns/index.vue").then(m => m.default || m)
  },
  {
    name: index1NCRTrmS3iMeta?.name ?? "dashboard",
    path: index1NCRTrmS3iMeta?.path ?? "/dashboard",
    meta: index1NCRTrmS3iMeta || {},
    alias: index1NCRTrmS3iMeta?.alias || [],
    redirect: index1NCRTrmS3iMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexpL9CMoLDU6Meta?.name ?? "help",
    path: indexpL9CMoLDU6Meta?.path ?? "/help",
    meta: indexpL9CMoLDU6Meta || {},
    alias: indexpL9CMoLDU6Meta?.alias || [],
    redirect: indexpL9CMoLDU6Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/help/index.vue").then(m => m.default || m)
  },
  {
    name: index3sYrVATQ3AMeta?.name ?? "index",
    path: index3sYrVATQ3AMeta?.path ?? "/",
    meta: index3sYrVATQ3AMeta || {},
    alias: index3sYrVATQ3AMeta?.alias || [],
    redirect: index3sYrVATQ3AMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexNLM5Sez1UTMeta?.name ?? "inquiry",
    path: indexNLM5Sez1UTMeta?.path ?? "/inquiry",
    meta: indexNLM5Sez1UTMeta || {},
    alias: indexNLM5Sez1UTMeta?.alias || [],
    redirect: indexNLM5Sez1UTMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/inquiry/index.vue").then(m => m.default || m)
  },
  {
    name: editorUccsEUE5SyMeta?.name ?? "ivr_trees-id-editor",
    path: editorUccsEUE5SyMeta?.path ?? "/ivr_trees/:id()/editor",
    meta: editorUccsEUE5SyMeta || {},
    alias: editorUccsEUE5SyMeta?.alias || [],
    redirect: editorUccsEUE5SyMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/ivr_trees/[id]/editor.vue").then(m => m.default || m)
  },
  {
    name: index0ouBfw12EnMeta?.name ?? "ivr_trees",
    path: index0ouBfw12EnMeta?.path ?? "/ivr_trees",
    meta: index0ouBfw12EnMeta || {},
    alias: index0ouBfw12EnMeta?.alias || [],
    redirect: index0ouBfw12EnMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/ivr_trees/index.vue").then(m => m.default || m)
  },
  {
    name: verify_emailDEWmuYn0YnMeta?.name ?? "profiles-user_id-verify_email",
    path: verify_emailDEWmuYn0YnMeta?.path ?? "/profiles/:user_id()/verify_email",
    meta: verify_emailDEWmuYn0YnMeta || {},
    alias: verify_emailDEWmuYn0YnMeta?.alias || [],
    redirect: verify_emailDEWmuYn0YnMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/profiles/[user_id]/verify_email.vue").then(m => m.default || m)
  },
  {
    name: welcome_dashboardKQtuWgVL0aMeta?.name ?? "profiles-user_id-welcome_dashboard",
    path: welcome_dashboardKQtuWgVL0aMeta?.path ?? "/profiles/:user_id()/welcome_dashboard",
    meta: welcome_dashboardKQtuWgVL0aMeta || {},
    alias: welcome_dashboardKQtuWgVL0aMeta?.alias || [],
    redirect: welcome_dashboardKQtuWgVL0aMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/profiles/[user_id]/welcome_dashboard.vue").then(m => m.default || m)
  },
  {
    name: indexwX6vjl9WjvMeta?.name ?? "profiles",
    path: indexwX6vjl9WjvMeta?.path ?? "/profiles",
    meta: indexwX6vjl9WjvMeta || {},
    alias: indexwX6vjl9WjvMeta?.alias || [],
    redirect: indexwX6vjl9WjvMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/profiles/index.vue").then(m => m.default || m)
  },
  {
    name: indexA9fBmCGEkDMeta?.name ?? "sub_users-users",
    path: indexA9fBmCGEkDMeta?.path ?? "/sub_users/users",
    meta: indexA9fBmCGEkDMeta || {},
    alias: indexA9fBmCGEkDMeta?.alias || [],
    redirect: indexA9fBmCGEkDMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/sub_users/users/index.vue").then(m => m.default || m)
  },
  {
    name: indexlP0ZmCnY09Meta?.name ?? "terms_and_conditions",
    path: indexlP0ZmCnY09Meta?.path ?? "/terms_and_conditions",
    meta: indexlP0ZmCnY09Meta || {},
    alias: indexlP0ZmCnY09Meta?.alias || [],
    redirect: indexlP0ZmCnY09Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/terms_and_conditions/index.vue").then(m => m.default || m)
  },
  {
    name: index8KY6WEMl0hMeta?.name ?? "users-password-edit",
    path: index8KY6WEMl0hMeta?.path ?? "/users/password/edit",
    meta: index8KY6WEMl0hMeta || {},
    alias: index8KY6WEMl0hMeta?.alias || [],
    redirect: index8KY6WEMl0hMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/users/password/edit/index.vue").then(m => m.default || m)
  },
  {
    name: indexMD2rFjntXzMeta?.name ?? "users-password-new",
    path: indexMD2rFjntXzMeta?.path ?? "/users/password/new",
    meta: indexMD2rFjntXzMeta || {},
    alias: indexMD2rFjntXzMeta?.alias || [],
    redirect: indexMD2rFjntXzMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/users/password/new/index.vue").then(m => m.default || m)
  },
  {
    name: indexcdimLKEzn7Meta?.name ?? "users-sign_in",
    path: indexcdimLKEzn7Meta?.path ?? "/users/sign_in",
    meta: indexcdimLKEzn7Meta || {},
    alias: indexcdimLKEzn7Meta?.alias || [],
    redirect: indexcdimLKEzn7Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src2320306735/src/pages/users/sign_in/index.vue").then(m => m.default || m)
  }
]